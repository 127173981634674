<template>
  <div class="SimpleBinding">
    <SimpleDialog ref="dialog" :width="width" :title="title">
      <SimpleForm :inline="true" :show-form="barShowForm" :model="addForm">
        <template #last>
          <el-button type="primary" size="mini" @click="handleAddDevice">
            {{ showTypeButtonName }}
          </el-button>
        </template>
        <template #default>
          <simple-table class="table" :data="data" :show-form="showForm" :can-edit="canEdit" :can-delete="canDelete" :handle-edit="handleEdit"
                        :handle-delete="handleDelete"
          />
          <div class="NumFooter">
            <el-button @click.native="$refs.dialog.close()">
              取消
            </el-button>
            <el-button type="primary" :loading="$store.state.isLoading" @click.native="handleAddOrUpdate">
              确认
            </el-button>
          </div>
        </template>
      </SimpleForm>
    </SimpleDialog>

    <SimpleDialog ref="editDialog" :title="typeTitle" :width="width">
      <simple-table :data="types" :show-form="typeShowForm" :can-delete="false" :can-edit="false">
        <template #operation="{row}">
          <el-button type="primary" size="small" @click.native="handleEditDeviceAdd(row)">
            {{ addTypeButtonName }}
          </el-button>
        </template>
      </simple-table>
      <div class="col">
        <el-col :span="24">
          <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :page-size="typeSearchCondition.size"
            :current-page.sync="typeSearchCondition.page"
            :page-sizes="[10, 20]"
            :total="total"
            style="float: right"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </el-col>
      </div>
    </SimpleDialog>
  </div>
</template>

<script>
import SimpleDialog from './SimpleDialog'
import SimpleForm from './SimpleForm'
import SimpleTable from './SimpleTable'
import ArrayUtils from '@/common/js/ArrayUtils'
let that
export default {
  name: 'SimpleBinding',
  components: { SimpleTable, SimpleForm, SimpleDialog },
  props: {
    title: { type: String, required: true },
    width: { type: String, default: '50%', required: false },
    typeTitle: { type: String, required: true },
    showTypeButtonName: { type: String, required: true },
    addTypeButtonName: { type: String, required: true },
    searchUrl: { required: false, type: String, default: null },
    searchTypeUrl: { required: true, type: String },
    addOrUpdateUrl: { required: true, type: String },
    barShowForm: { type: Array, default: null, required: false },
    addForm: { type: Object, default: null, required: false },
    showForm: { type: Array, default: null, required: false },
    typeShowForm: { type: Array, default: null, required: false },
    typeSearchCondition: {
      type: Object,
      default: () => {
        return {
          page: 0,
          size: 20,
        }
      },
      required: false
    },
    formatCommitData: { required: false, type: Function, default: (item) => item },
    canEdit: { type: [Function, Boolean], default: () => true, required: false },
    canDelete: { type: [Function, Boolean], default: () => true, required: false },
    handleEdit: { type: Function, default: () => {}, required: false },
    validForm: { type: Function, default: () => true, required: false },
    handleRow: { type: Function, default: (item) => item, required: false },
    formatTypeResult: { type: Function, default: (item) => item, required: false },
    handleDelete: {
      type: Function,
      default: (row, data) => {
        data.splice(ArrayUtils.getArrayObject(data, 'id', row.id).$index, 1)
        that.filterDevice()
      },
      required: false
    },
  },
  data() {
    return {
      data: null,
      dataOrigin: null,
      types: null,
      typeOrigin: null,
      total: null,
      totalOrigin: null
    }
  },
  beforeCreate() {
    that = this
  },
  methods: {
    visible() {
      this.$refs.dialog.changeVisible()
      this.getTypes().then(() => this.search())
    },
    filterDevice() {
      this.total = this.totalOrigin
      const temp = []
      this.typeOrigin.forEach(item => {
        if (!ArrayUtils.getArrayObject(this.data, 'id', item.id)) {
          temp.push(item)
        } else {
          this.total -= 1
        }
      })
      this.types = temp
    },
    search() {
      if (this.searchUrl) {
        this.$get(this.searchUrl, this.addForm).then(result => {
          this.dataOrigin = result.data
          this.data = this.formatCommitData(result.data, this.typeOrigin)
        }).then(() => {
          this.filterDevice()
        })
      } else {
        this.data = []
        this.dataOrigin = []
        this.filterDevice()
      }
    },
    getTypes() {
      return this.$get(this.searchTypeUrl, Object.assign({}, this.typeSearchCondition, { page: this.typeSearchCondition.page - 1 })).then((result) => {
        this.typeOrigin = this.formatTypeResult(result.data)
        this.totalOrigin = result.data.totalElements
      })
    },
    handleAddDevice() {
      this.$refs.editDialog.changeVisible()
    },
    handleEditDeviceAdd(row) {
      this.handleRow(row)
      this.types.splice(ArrayUtils.getArrayObject(this.types, 'id', row.id).$index, 1)
      this.data.push(row)
      this.total -= 1
    },
    handleAddOrUpdate() {
      if (this.validForm(this.data)) {
        this.$post(this.addOrUpdateUrl, { project: this.addForm, data: this.data }).then(result => {
          this.$refs.dialog.close()
          this.$emit('afterAddOrUpdate')
        })
      }
    },
    // 分页开始
    handleCurrentChange(val) {
      this.typeSearchCondition.page = val
      this.getTypes().then(() => this.search())
    },
    handleSizeChange(val) {
      this.typeSearchCondition.page = 1
      this.typeSearchCondition.size = val
      this.getTypes().then(() => this.search())
    },
    // 分页结束
  }
}
</script>

<style>
.NumFooter{
  padding: 20px 20px 0px;
  text-align: right;
}
.table th{
  padding: 5px 0!important;
}
.table td{
  padding: 5px 0!important;
}
.col{
  padding: 10px 0;
}
</style>
