<template>
  <div class="Account">
    <simple-c-u-r-d
      ref="account"
      search-url="/api/account/list"
      add-or-update-url="/api/account/addOrUpdate"
      delete-url="/api/account/delete"
      :model="addForm"
      :show-form="showForm"
      is-pageable
      label-width="120px"
      operation-width="350px"
      :add-form-rules="addFormRules"
      :format-commit-data="formatCommitData"
      :show-edit-button="showButton"
      :show-delete-button="(row)=>showButton(row)&&row.name!==user().account.name"
      @afterAddOrUpdate="afterAddOrUpdate"
      @add="add"
      @edit="edit"
    >
      <template #searchOperation>
        <el-button v-if="user().role.authority.split(',').includes('AUTH_ROLE')" type="primary" plain size="medium" @click="showRoleCard">
          角色管理
        </el-button>
      </template>
      <template #operation="{row}">
        <el-button v-if="row.roleName !=='系统管理员' " type="primary" size="small" @click="editProject(row)">
          绑定项目
        </el-button>
        <el-button v-if="row.status===0" type="primary" size="small" @click="changePassword(row.id)">
          重置密码
        </el-button>
        <el-button v-if="row.status===1&&row.name!=='admin'" type="primary" size="small" @click="updateAccount(row,0)">
          启用
        </el-button>
        <el-button v-if="row.status===0&&row.name!=='admin'&&row.name!==user().account.name" type="primary" size="small" @click="updateAccount(row,1)">
          停用
        </el-button>
      </template>
    </simple-c-u-r-d>
    <!--  角色管理 -->
    <simple-dialog v-if="user().role.authority.split(',').includes('AUTH_ROLE')" ref="roleDialog" title="角色管理" :close-on-click-modal="false">
      <simple-c-u-r-d
        ref="role"
        search-url="/api/role/list"
        add-or-update-url="/api/role/addOrUpdate"
        delete-url="/api/role/delete"
        :model="addFormRole"
        :show-form="showFormRole"
        label-width="120px"
        :add-form-rules="addFormRulesRole"
        operation-width="200px"
        :format-commit-data="formatCommitRole"
        @afterDelete="handleCommit"
        @afterAddOrUpdate="handleCommit"
      />
    </simple-dialog>

    <!--密码回显-->
    <simple-dialog ref="passDialog" title="账号密码" :close-on-click-modal="false">
      <el-form>
        <el-form-item label="账号密码(密码只显示一次，请保存密码)" prop="pass">
          <el-input ref="passwordInput" v-model.trim="currentAccountPassword" autocomplete="off" readonly />
        </el-form-item>
        <el-form-item>
          <el-button :type="copyButtonType" @click="copyPassword">
            {{ copyTitle }}
          </el-button>
          <el-button type="primary" @click="confirmPassword">
            确定
          </el-button>
        </el-form-item>
      </el-form>
    </simple-dialog>
    <!--项目绑定-->
    <SimpleBinding ref="selectProject" title="项目绑定" type-title="项目" show-type-button-name="选择项目"
                   add-type-button-name="添加项目"
                   search-url="/api/accountToProject/getByAccountId"
                   search-type-url="/api/project/list"
                   add-or-update-url="/api/accountToProject/addOrUpdate"
                   :bar-show-form="barShowForm"
                   :add-form="infoAddForm"
                   :show-form="projectShowForm"
                   :type-show-form="editProjectShowForm"
                   :format-commit-data="formatCommitProjectData"
                   :format-type-result="formatTypeResult"
                   :can-edit="false"
    />
  </div>
</template>

<script>
import SimpleCURD from '@/components/simple/SimpleCURD'
import ArrayUtils from '@/common/js/ArrayUtils'
import SimpleBinding from '@/components/simple/SimpleBinding'
import SimpleDialog from '@/components/simple/SimpleDialog'

export default {
  name: 'Account',
  components: { SimpleDialog, SimpleBinding, SimpleCURD },
  data() {
    const bindingForm = [
      { param: 'name', name: '项目名称', maxlength: '100', search: true },
      {
        param: 'stage',
        name: '阶段',
        formatter: (row) => {
          switch (row.stage.ordinal) {
          case 0:return <el-tag color="#ffb149">{row.stage.description}</el-tag>
          case 1:return <el-tag color="#f0f80d">{row.stage.description}</el-tag>
          case 2:return <el-tag color="#25ff29">{row.stage.description}</el-tag>
          default:return <el-tag type="info" >未定义</el-tag>
          }
        },
        search: true
      },
      { param: 'company', name: '业主单位', maxlength: '100', search: true, style: 'color:red;' },
    ]
    return {
      name: null,
      // account start
      showForm: [
        { param: 'name', name: '账号', maxlength: '100', search: true, clearable: true },
        { param: 'fullName', name: '账号昵称', maxlength: '100', clearable: true },
        { param: 'mobile', name: '手机号', maxlength: '20', search: true, clearable: true },
        {
          param: 'roleName',
          name: '角色',
          type: 'select',
          disabled: (type) => type === 'update' && this.name === 'admin',
          option: () => {
            const one = this.user()
            return this.roles.map(item => {
              const temp = {
                param: item.name,
                value: item.name
              }
              if (this.$refs.account.selectType === 'search') {
                return temp
              } else {
                const auth = one.role.authority.split(',')
                if (auth.includes('AUTH_ACCOUNT')) {
                  return temp
                } else {
                  if (auth.includes('AUTH_MIN_ACCOUNT') && !item.authority.includes('AUTH_MIN_ACCOUNT') && !item.authority.includes('AUTH_ACCOUNT')) {
                    return temp
                  } else {
                    return null
                  }
                }
              }
            }).filter(item => item)
          },
          search: true,
          clearable: true
        },
        {
          param: 'status',
          name: '状态',
          type: 'select',
          formatter: function(row) { switch (row.status) { case 0:return <el-tag type="success">正常</el-tag>; case 1:return <el-tag type="warning">停用</el-tag>; default:return <el-tag type="info">未定义</el-tag> } },
          option: () => {
            return [{ value: 0, label: '正常' }, { value: 1, label: '停用' }]
          },
          notShow: () => true,
          search: true,
          clearable: true
        },
        { param: 'description', name: '备注', maxlength: '1000', type: 'textarea', clearable: true },
      ],
      addForm: { name: null, fullName: null, mobile: null, roleName: null, status: null, description: null },
      addFormRules: {
        name: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        fullName: [{ required: true, message: '请输入账号昵称', trigger: 'blur' }],
        roleName: [{ required: true, message: '请选择角色', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
      },
      // account end
      currentAccountPassword: '',
      copyTitle: '复制',
      copyButtonType: 'primary',
      // role start
      addFormRulesRole: {
        name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
        authority: [{ required: true, message: '请选择权限', trigger: 'blur' }],
      },
      showFormRole: [
        { param: 'name', name: '角色', maxlength: '100', width: 200 },
        {
          param: 'authority',
          name: '权限',
          type: 'transfer',
          transfer: () => this.auths.map(item => {
            return {
              key: item.name,
              label: item.auth,
              disabled: false,
            }
          }),
          formatter: (row) => <span>
            {row.authority.map(item => {
              return <span style="margin:2px;line-height:40px;"><el-tag>{ArrayUtils.getArrayObjectFieldByOtherField(this.auths, 'auth', 'name', item)[0]}</el-tag></span>
            })}
          </span>
        }
      ],
      addFormRole: { name: null, authority: [] },
      roles: [],
      auths: [],
      levels: [],
      // role end
      // project start
      barShowForm: [
        { param: 'name', name: '账号名称', readonly: true },
      ],
      infoAddForm: {
        id: null,
        name: null,
        isManage: null
      },
      projectShowForm: bindingForm,
      editProjectShowForm: bindingForm
      // project end
    }
  },
  created() {
    this.getRole()
    this.getAuth()
  },
  inject: ['user', 'reFlushSession'],
  methods: {
    // account start
    formatCommitData(result) {
      const z = this.user()
      const a = z.account
      const b = z.role
      return result.filter(item => {
        if (a.id === 0) {
          return true
        }
        if (item.id === a.id) {
          return true
        }
        const auth = b.authority.split(',')
        if (auth.includes('AUTH_ACCOUNT')) {
          return true
        }
        const ia = ArrayUtils.getArrayObject(this.roles, 'name', item.roleName).authority
        if (auth.includes('AUTH_MIN_ACCOUNT') && !ia.includes('AUTH_ACCOUNT') && !ia.includes('AUTH_MIN_ACCOUNT')) {
          return true
        } else {
          return false
        }
      })
    },
    edit(row) {
      this.name = row.name
    },
    showButton(row) {
      if (row.name === 'admin') {
        return false
      }
      return true
    },
    add(addForm) {
      addForm.status = 0
    },
    afterAddOrUpdate(data, callback) {
      if (data) {
        this.$refs.passDialog.changeVisible()
        this.currentAccountPassword = data.password
      }
      callback()
    },
    // account end
    // role start
    formatCommitRole(result) {
      return result
    },
    handleCommit(data, callback) {
      callback()
      this.getRole()
      this.reFlushSession()
      this.$refs.account.search()
    },
    // role end
    // show dialog
    showRoleCard() {
      this.$refs.roleDialog.changeVisible()
      this.getRole()
    },
    editProject(row) {
      const auth = ArrayUtils.getArrayObject(this.roles, 'name', row.roleName).authority
      if (!auth.includes('AUTH_ACCOUNT') && !auth.includes('AUTH_MIN_ACCOUNT')) {
        this.infoAddForm.isManage = 0
      } else {
        this.infoAddForm.isManage = 1
      }
      this.infoAddForm.id = row.id
      this.infoAddForm.name = row.name
      this.$refs.selectProject.visible()
    },
    // hidden dialog
    // password start
    updateAccount(row, status) {
      row.status = status
      this.$post('/api/account/addOrUpdate', row).then(() => this.$refs.account.search())
    },
    changePassword(id) {
      this.$confirm('确认重置密码吗?', '提示', {
        type: 'warning',
      }).then(() => {
        this.$get('/api/account/resetPassword', { id }).then((result) => {
          this.$message({
            message: '重置密码成功',
            type: 'success',
          })
          this.currentAccountPassword = result.data
          this.$refs.passDialog.changeVisible()
        })
      })
    },
    confirmPassword() {
      this.$refs.passDialog.close()
    },
    copyPassword() {
      this.$refs.passwordInput.select()
      document.execCommand('Copy')
      this.copyButtonType = 'info'
      this.copyTitle = '已复制'
    },
    // password end
    getRole() {
      this.$get('/api/role/list').then(result => {
        this.roles = result.data
      })
    },
    getAuth() {
      this.$get('/api/role/getAuth').then(result => {
        this.auths = result.data
      })
    },
    // project start
    formatCommitProjectData(a, b) {
      return a.map(item => {
        const temp = ArrayUtils.getArrayObject(b, 'id', item.projectId)
        return temp
      })
    },
    formatTypeResult(result) {
      return result.content.filter(item => {
        return item.stage.ordinal < 2
      })
    }
    // project end
  },
}
</script>

<style scoped>
</style>
